import router from '@/router'
// eslint-disable-next-line object-curly-newline
import { reactive, getCurrentInstance, watch, toRefs } from '@vue/composition-api'

export const isDate = date => {
  const regex = /\b(janvier|février|mars|avril|mai|juin|juillet|août|septembre|octobre|novembre|décembre)\b/
  return regex.test(date)
}

const regexPhoneNumberCheck = /^(\+33\s?|0)[1-9](\s?\d{2}){4}$/g

export const isPhoneNumber = phoneNumber => regexPhoneNumberCheck.test(phoneNumber.replace(/\s/g, ''))

export const formatPhoneNumber = phoneNumber => {
  if (regexPhoneNumberCheck.test(phoneNumber.replace(/\s/g, ''))) {
    return phoneNumber.replace(/\s/g, '').replace(/^0/, '+33')
  }
  return phoneNumber
}

export const isObject = obj => typeof obj === 'object' && obj !== null

export const isToday = date => {
  const today = new Date()
  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  )
}

const getRandomFromArray = array => array[crypto.getRandomValues(new Uint32Array(1))[0] % array.length]

// ? Light and Dark variant is not included
// prettier-ignore
export const getRandomBsVariant = () => getRandomFromArray(['primary', 'secondary', 'success', 'warning', 'danger', 'info'])

export const isDynamicRouteActive = route => {
  const { route: resolvedRoute } = router.resolve(route)
  return resolvedRoute.path === router.currentRoute.path
}

// Thanks: https://medium.com/better-programming/reactive-vue-routes-with-the-composition-api-18c1abd878d1
export const useRouter = () => {
  const vm = getCurrentInstance().proxy
  const state = reactive({
    route: vm.$route,
  })

  watch(
    () => vm.$route,
    r => {
      state.route = r
    },
  )

  return { ...toRefs(state), router: vm.$router }
}
